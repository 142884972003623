<template>
  <v-select
    v-model="value"
    v-bind="bind"
    :clearable="clearable"
    closable-chips
    :item-title="itemTitle"
    :item-value="itemValue"
    :items="items"
    :label="label"
    :multiple="multiple"
    :return-object="returnObject"
  >
    <template v-if="$slots.selection" #selection="{ item }">
      <slot name="selection" v-bind="{ item: item.raw }" />
    </template>

    <template #item="{ props, item }">
      <div :data-cy-select-option-id="itemId(item.raw as any)">
        <slot name="item" v-bind="{ props, item: item.raw }">
          <v-list-item v-bind="props" :title="item.title" />
        </slot>
      </div>
    </template>

    <template #prepend-item>
      <slot name="prepend-item" />
    </template>
  </v-select>
</template>

<script lang="ts" setup>
const properties = defineProps({
  ...appFormInputProperties,
  items: { type: Array, default: () => [] },
  itemTitle: { type: String, default: undefined },
  itemValue: { type: String, default: undefined },
  multiple: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  returnObject: { type: Boolean, default: true },
});

defineSlots<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selection(props: { item: any }): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item(props: { item: any; props: any }): void;
  "prepend-item"(): void;
}>();
const { value, bind } = useAppFormInput(properties);

const itemId = (item: string | Record<string, unknown>) => {
  if (typeof item === "string") return item;

  if (properties.itemValue) return item[properties.itemValue];
};
</script>
